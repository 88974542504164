import { NgModule } from '@angular/core';
import { Data, Route, RouterModule, Routes } from '@angular/router';
import { RequiredPermissions } from '@models/authorization/permission.model';
import { NewsArticlePreviewComponent } from './app-pages/news-articles/preview/news-article-preview.component';
import { NewsArticlePreviewResolver } from './app-pages/news-articles/preview/news-article-preview.resolver';
import { AuthenticatedTemplateComponent } from './components/authenticated-template/authenticated-template.component';
import { AuthGuard } from './guards/auth.guard';
import { PermissionsGuard } from './guards/permissions.guard';

export interface AuthorizedRoute extends Route {
  data?: AuthorizedRouteData;
  children?: AuthorizedRoute[];
}

export interface AuthorizedRouteData extends Data {
  permissions: RequiredPermissions;
  pageTitle?: string;
}

const authorizedRoutes: AuthorizedRoute[] = [
  {
    path: 'page',
    loadChildren: () => import('./app-pages/page/page.module').then(m => m.PageModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./app-pages/users/users.module').then(m => m.UsersModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Users',
      permissions: {
        menuAccess: ['Portal.Admin.Users.MenuAccess'],
      },
    },
  },
  {
    path: 'partners',
    loadChildren: () => import('./app-pages/partners/partners.module').then(m => m.PartnersModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Partners',
      permissions: {
        menuAccess: ['Portal.Admin.Partners.MenuAccess'],
        oneAtLeast: ['Portal.Admin.Partners.Read'],
      },
    },
  },
  {
    path: 'agreements',
    loadChildren: () => import('./app-pages/agreements/agreements.module').then(m => m.AgreementsModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Agreements',
      permissions: {
        menuAccess: ['Portal.Admin.Agreements.MenuAccess'],
        oneAtLeast: ['Portal.Admin.Agreements.Read'],
      },
    },
  },
  {
    path: 'permissions',
    loadChildren: () => import('./app-pages/authorization/permissions/permissions.module').then(m => m.PermissionsModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Permissions',
      permissions: {
        menuAccess: ['Portal.Admin.Authorization.Permissions.MenuAccess'],
      },
    },
  },
  {
    path: 'roles',
    loadChildren: () => import('./app-pages/authorization/roles/roles.module').then(m => m.RolesModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Roles',
      permissions: {
        menuAccess: ['Portal.Admin.Authorization.Roles.MenuAccess'],
      },
    },
  },
  {
    path: 'role-groups',
    loadChildren: () => import('./app-pages/authorization/role-groups/role-groups.module').then(m => m.RoleGroupsModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Role Groups',
      permissions: {
        menuAccess: ['Portal.Admin.Authorization.RoleGroups.MenuAccess'],
      },
    },
  },
  {
    path: 'common-data',
    loadChildren: () => import('./app-pages/common-data/common-data.module').then(m => m.CommonDataModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Common Data',
      permissions: {
        menuAccess: ['Portal.Admin.CommonData.Read'],
      },
    },
  },
  {
    path: 'action-logs',
    loadChildren: () => import('./app-pages/action-logs/action-logs.module').then(m => m.ActionLogsModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Action Logs',
      permissions: {
        menuAccess: ['Portal.Admin.ActionLogs.MenuAccess'],
      },
    },
  },
  {
    path: 'marketing',
    children: [
      { path: '', redirectTo: 'announcements', pathMatch: 'full' },
      {
        path: 'announcements',
        loadChildren: () => import('./app-pages/marketing/announcements/announcements.module').then(m => m.AnnouncementsModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Announcements',
          permissions: {
            menuAccess: ['Portal.Admin.Marketing.Announcements.MenuAccess'],
          },
        },
      },
      {
        path: 'documents',
        loadChildren: () => import('./app-pages/documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Documents',
          permissions: {
            menuAccess: ['Portal.Admin.Marketing.Documents.MenuAccess'],
          },
        },
      },
      {
        path: 'topics',
        loadChildren: () => import('./app-pages/marketing/topics/topics.module').then(m => m.TopicsModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Topics',
          permissions: {
            menuAccess: ['Portal.Admin.Marketing.Topics.MenuAccess'],
          },
        },
      },
      {
        path: 'news-articles',
        loadChildren: () => import('./app-pages/news-articles/news-articles.module').then(m => m.NewsArticlesModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'News',
          permissions: {
            menuAccess: ['Portal.Admin.Marketing.NewsArticles.MenuAccess'],
          },
        },
      },
      {
        path: 'news-articles-comments',
        loadChildren: () => import('./app-pages/marketing/comments/comments.module').then(m => m.CommentsModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Comments',
          permissions: {
            menuAccess: ['Portal.Admin.Marketing.Comments.MenuAccess'],
          },
        },
      },
    ],
  },
  {
    path: 'file-management',
    children: [
      { path: '', redirectTo: 'branches', pathMatch: 'full' },
      {
        path: 'branches',
        loadChildren: () => import('./app-pages/file-management/branches/branches.module').then(m => m.BranchesModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Branches',
          permissions: {
            menuAccess: ['Portal.Admin.FileManagement.Branches.MenuAccess'],
          },
        },
      },
      {
        path: 'files',
        loadChildren: () => import('./app-pages/file-management/files/files.module').then(m => m.FilesModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Files',
          permissions: {
            menuAccess: ['Portal.Admin.FileManagement.Files.MenuAccess'],
          },
        },
      },
    ],
  },
  {
    path: 'products',
    loadChildren: () => import('./app-pages/products-orders/products/products.module').then(m => m.ProductsModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Products',
      permissions: {
        menuAccess: ['Portal.Admin.ProductsAndOrders.Products.MenuAccess'],
      },
    },
  },
  {
    path: 'assessments',
    loadChildren: () => import('./app-pages/products-orders/assessments/assessments.module').then(m => m.AssessmentsModule),
    canActivate: [PermissionsGuard],
    data: {
      pageTitle: 'Assessments',
      permissions: {
        menuAccess: ['Portal.Admin.ProductsAndOrders.Assessments.MenuAccess'],
      },
    },
  },
  {
    path: 'notifications',
    children: [
      { path: '', redirectTo: 'groups', pathMatch: 'full' },
      {
        path: 'groups',
        loadChildren: () => import('./app-pages/notifications/receiver-groups/receiver-groups.module').then(m => m.ReceiverGroupsModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Receiver Groups',
          permissions: {
            menuAccess: ['Portal.Admin.Notifications.ReceiverGroups.MenuAccess'],
          },
        },
      },
      {
        path: 'external-templates',
        loadChildren: () =>
          import('./app-pages/notifications/external-templates/external-templates.module').then(m => m.ExternalTemplatesModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'External Templates',
          permissions: {
            menuAccess: ['Portal.Admin.Notifications.ExternalTemplates.MenuAccess'],
          },
        },
      },
      {
        path: 'template-groups',
        loadChildren: () => import('./app-pages/notifications/template-groups/template-groups.module').then(m => m.TemplateGroupsModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Template Groups',
          permissions: {
            menuAccess: ['Portal.Admin.Notifications.TemplateGroups.MenuAccess'],
          },
        },
      },
      {
        path: 'templates',
        loadChildren: () => import('./app-pages/notifications/templates/templates.module').then(m => m.TemplatesModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Templates',
          permissions: {
            menuAccess: ['Portal.Admin.Notifications.Templates.MenuAccess'],
          },
        },
      },
      {
        path: 'logs',
        loadChildren: () => import('./app-pages/notifications/logs/notification-logs.module').then(m => m.NotificationLogsModule),
        canActivate: [PermissionsGuard],
        data: {
          pageTitle: 'Logs',
          permissions: {
            menuAccess: ['Portal.Admin.Notifications.Logs.MenuAccess'],
          },
        },
      },
    ],
  },
];

const routes: Routes = [
  {
    path: 'marketing/news-articles/:articleId/preview',
    component: NewsArticlePreviewComponent,
    resolve: { data: NewsArticlePreviewResolver },
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'News Article Preview',
      permissions: { menuAccess: ['Portal.Admin.Marketing.NewsArticles.Preview.ActionAccess'] },
    },
  },
  {
    path: '',
    component: AuthenticatedTemplateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./app-pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          pageTitle: 'Dashboard',
        },
      },
      ...authorizedRoutes,
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'page/page-not-found' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [PermissionsGuard, AuthGuard, NewsArticlePreviewResolver],
  exports: [RouterModule],
})
export class AppRoutingModule {}
