import { HttpErrorResponse } from '@angular/common/http';
import {
  AddNewsArticlePromotionRequest,
  CreateNewsArticleRequest,
  NewsArticle,
  NewsArticleComment,
  NewsArticleCreateFileResponse,
  NewsArticleListItem,
  NewsArticleUpdateContentRequest,
  NewsArticleUpdateContentResponse,
  UpdateNewsArticlePromotionRequest,
  UpdateNewsArticleRegionRequest,
  UpdateNewsArticleRequest,
} from '@models/news-article/news-article.model';
import { createAction, props } from '@ngrx/store';
import { State } from './news-articles.reducer';

export const ToggleOptions = ['Active', 'All'] as const;
export type ToggleOptionsName = (typeof ToggleOptions)[number];

const NAMESPACE = '[NewsArticles]';

export const getAllNewsArticles = createAction(`${NAMESPACE} GetAllNewsArticles`);
export const getAllNewsArticlesComplete = createAction(
  `${NAMESPACE} GetAllNewsArticlesComplete`,
  props<{ newsArticles: NewsArticleListItem[] }>(),
);
export const getAllNewsArticlesError = createAction(`${NAMESPACE} GetAllNewsArticlesError`, props<{ err: HttpErrorResponse }>());

export const selectNewsArticle = createAction(`${NAMESPACE} SelectNewsArticle`, props<{ id: string; includeContent: boolean }>());
export const selectNewsArticleComplete = createAction(`${NAMESPACE} SelectNewsArticleComplete`, props<{ newsArticle: NewsArticle }>());
export const selectNewsArticleError = createAction(`${NAMESPACE} SelectNewsArticleError`, props<{ err: HttpErrorResponse }>());

export const addNewsArticle = createAction(`${NAMESPACE} AddNewsArticle`, props<{ newsArticle: CreateNewsArticleRequest }>());
export const addNewsArticleComplete = createAction(`${NAMESPACE} AddNewsArticleComplete`, props<{ newsArticle: NewsArticleListItem }>());
export const addNewsArticleError = createAction(`${NAMESPACE} AddNewsArticleError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedNewsArticle = createAction(`${NAMESPACE} DeleteSelectedNewsArticle`);
export const deleteSelectedNewsArticleComplete = createAction(`${NAMESPACE} DeleteSelectedNewsArticleComplete`);
export const deleteSelectedNewsArticleError = createAction(
  `${NAMESPACE} DeleteSelectedNewsArticleError`,
  props<{ err: HttpErrorResponse }>(),
);

export const updateNewsArticle = createAction(
  `${NAMESPACE} UpdateNewsArticle`,
  props<{ id: string; newsArticle: UpdateNewsArticleRequest }>(),
);
export const updateNewsArticleComplete = createAction(
  `${NAMESPACE} UpdateNewsArticleComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const updateNewsArticleError = createAction(`${NAMESPACE} UpdateNewsArticleError`, props<{ err: HttpErrorResponse }>());

export const activateNewsArticle = createAction(`${NAMESPACE} ActivateNewsArticle`, props<{ id: string }>());
export const activateNewsArticleComplete = createAction(
  `${NAMESPACE} ActivateNewsArticleComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const activateNewsArticleError = createAction(`${NAMESPACE} ActivateNewsArticleError`, props<{ err: HttpErrorResponse }>());

export const deactivateNewsArticle = createAction(`${NAMESPACE} DeactivateNewsArticle`, props<{ id: string }>());
export const deactivateNewsArticleComplete = createAction(
  `${NAMESPACE} DeactivateNewsArticleComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const deactivateNewsArticleError = createAction(`${NAMESPACE} DeactivateNewsArticleError`, props<{ err: HttpErrorResponse }>());

export const setNewsArticleContent = createAction(
  `${NAMESPACE} SetNewsArticleContent`,
  props<{ id: string; content: NewsArticleUpdateContentRequest }>(),
);
export const setNewsArticleContentComplete = createAction(
  `${NAMESPACE} SetNewsArticleContentComplete`,
  props<{ newContent: NewsArticleUpdateContentResponse }>(),
);
export const setNewsArticleContentError = createAction(`${NAMESPACE} SetNewsArticleContentError`, props<{ err: HttpErrorResponse }>());

export const setNewsArticleTopics = createAction(`${NAMESPACE} SetNewsArticleTopics`, props<{ id: string; topics: string[] }>());
export const setNewsArticleTopicsComplete = createAction(
  `${NAMESPACE} SetNewsArticleTopicsComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const setNewsArticleTopicsError = createAction(`${NAMESPACE} SetNewsArticleTopicsError`, props<{ err: HttpErrorResponse }>());

export const setNewsArticleWorkloads = createAction(`${NAMESPACE} SetNewsArticleWorkloads`, props<{ id: string; workloads: string[] }>());
export const setNewsArticleWorkloadsComplete = createAction(
  `${NAMESPACE} SetNewsArticleWorkloadsComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const setNewsArticleWorkloadsError = createAction(`${NAMESPACE} SetNewsArticleWorkloadsError`, props<{ err: HttpErrorResponse }>());

export const setNewsArticleMembershipLevels = createAction(
  `${NAMESPACE} SetNewsArticleMembershipLevels`,
  props<{ id: string; membershipLevels: string[] }>(),
);
export const setNewsArticleMembershipLevelsComplete = createAction(
  `${NAMESPACE} SetNewsArticleMembershipLevelsComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const setNewsArticleMembershipLevelsError = createAction(
  `${NAMESPACE} SetNewsArticleMembershipLevelsError`,
  props<{ err: HttpErrorResponse }>(),
);

export const createNewsArticleThumbnailFile = createAction(
  `${NAMESPACE} CreateNewsArticleThumbnailFile`,
  props<{ id: string; file: File; updateReq: UpdateNewsArticleRequest }>(),
);

export const uploadNewsArticleThumbnailFile = createAction(
  `${NAMESPACE} UploadNewsArticleThumbnailFile`,
  props<{ response: NewsArticleCreateFileResponse; file: File; id: string; updateReq: UpdateNewsArticleRequest }>(),
);

export const getAllNewsArticleComments = createAction(
  `${NAMESPACE} GetAllNewsArticleComments`,
  props<{ articleId: string; includeSubComments: boolean }>(),
);
export const getAllNewsArticleCommentsComplete = createAction(
  `${NAMESPACE} GetAllNewsArticleCommentsComplete`,
  props<{ newsArticleComments: NewsArticleComment[] }>(),
);
export const getAllNewsArticleCommentsError = createAction(
  `${NAMESPACE} GetAllNewsArticleCommentsError`,
  props<{ err: HttpErrorResponse }>(),
);

export const deleteNewsArticleComment = createAction(
  `${NAMESPACE} DeleteNewsArticleComment`,
  props<{ articleId: string; commentId: string }>(),
);
export const deleteNewsArticleCommentComplete = createAction(
  `${NAMESPACE} DeleteNewsArticleCommentComplete`,
  props<{ commentId: string }>(),
);
export const deleteNewsArticleCommentError = createAction(
  `${NAMESPACE} DeleteNewsArticleCommentError`,
  props<{ err: HttpErrorResponse }>(),
);

export const selectNewsArticleRegion = createAction(`${NAMESPACE} SelectNewsArticleRegion`, props<{ regionId: string }>());

export const addNewsArticleRegions = createAction(`${NAMESPACE} AddNewsArticleRegions`, props<{ articleId: string; regions: string[] }>());
export const addNewsArticleRegionsComplete = createAction(
  `${NAMESPACE} AddNewsArticleRegionsComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const addNewsArticleRegionsError = createAction(`${NAMESPACE} AddNewsArticleRegionsError`, props<{ err: HttpErrorResponse }>());

export const approveNewsArticleRegion = createAction(
  `${NAMESPACE} ApproveNewsArticleRegion`,
  props<{ articleId: string; regionId: string }>(),
);
export const approveNewsArticleRegionComplete = createAction(
  `${NAMESPACE} ApproveNewsArticleRegionComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const approveNewsArticleRegionError = createAction(
  `${NAMESPACE} ApproveNewsArticleRegionError`,
  props<{ err: HttpErrorResponse }>(),
);

export const updateNewsArticleRegion = createAction(
  `${NAMESPACE} UpdateNewsArticleRegion`,
  props<{ articleId: string; regionId: string; region: UpdateNewsArticleRegionRequest }>(),
);
export const updateNewsArticleRegionComplete = createAction(
  `${NAMESPACE} UpdateNewsArticleRegionComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const updateNewsArticleRegionError = createAction(`${NAMESPACE} UpdateNewsArticleRegionError`, props<{ err: HttpErrorResponse }>());

export const deleteNewsArticleRegion = createAction(
  `${NAMESPACE} DeleteNewsArticleRegion`,
  props<{ articleId: string; regionId: string }>(),
);
export const deleteNewsArticleRegionComplete = createAction(
  `${NAMESPACE} DeleteNewsArticleRegionComplete`,
  props<{ articleId: string; regionId: string }>(),
);
export const deleteNewsArticleRegionError = createAction(`${NAMESPACE} DeleteNewsArticleRegionError`, props<{ err: HttpErrorResponse }>());

export const addNewsArticlePromotion = createAction(
  `${NAMESPACE} AddNewsArticlePromotion`,
  props<{ articleId: string; promotion: AddNewsArticlePromotionRequest }>(),
);
export const addNewsArticlePromotionComplete = createAction(
  `${NAMESPACE} AddNewsArticlePromotionComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const addNewsArticlePromotionError = createAction(`${NAMESPACE} AddNewsArticlePromotionError`, props<{ err: HttpErrorResponse }>());

export const updateNewsArticlePromotion = createAction(
  `${NAMESPACE} UpdateNewsArticlePromotion`,
  props<{ articleId: string; regionId: string; promotion: UpdateNewsArticlePromotionRequest }>(),
);
export const updateNewsArticlePromotionComplete = createAction(
  `${NAMESPACE} UpdateNewsArticlePromotionComplete`,
  props<{ newsArticle: NewsArticleListItem }>(),
);
export const updateNewsArticlePromotionError = createAction(
  `${NAMESPACE} UpdateNewsArticlePromotionError`,
  props<{ err: HttpErrorResponse }>(),
);

export const deleteNewsArticlePromotion = createAction(
  `${NAMESPACE} DeleteNewsArticlePromotion`,
  props<{ articleId: string; regionId: string }>(),
);
export const deleteNewsArticlePromotionComplete = createAction(
  `${NAMESPACE} DeleteNewsArticlePromotionComplete`,
  props<{ articleId: string; regionId: string }>(),
);
export const deleteNewsArticlePromotionError = createAction(
  `${NAMESPACE} DeleteNewsArticlePromotionError`,
  props<{ err: HttpErrorResponse }>(),
);

export const goToNewsArticleActions = createAction(`${NAMESPACE} GoToNewsArticlesActions`, props<{ id: string }>());

export const goToSelectedNewsArticleActions = createAction(`${NAMESPACE} GoToSelectedNewsArticleActions`, props<{ id: string }>());
export const goToNewsArticleListPage = createAction(`${NAMESPACE} GoToListPage`);

export const goToSelectedNewsArticleRegions = createAction(`${NAMESPACE} GoToSelectedNewsArticleRegions`, props<{ articleId: string }>());
export const goToSelectedNewsArticleRegionActions = createAction(
  `${NAMESPACE} GoToSelectedNewsArticleRegionActions`,
  props<{ articleId: string; regionId: string }>(),
);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
