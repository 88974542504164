import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.newsArticles);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectNewsArticles = createSelector(selectModuleState, state => state.newsArticles);
export const selectActiveNewsArticles = createSelector(selectModuleState, state =>
  state.newsArticles.filter(item => item.status === 'Active'),
);

export const selectSelectedNewsArticle = createSelector(selectModuleState, state => state.selectedNewsArticle);
export const selectSelectedNewsArticleId = createSelector(selectModuleState, state => state.selectedNewsArticle?.id ?? null);
export const selectSelectedNewsArticleLoading = createSelector(selectModuleState, state => state.isSelectedNewsArticleLoading);

export const selectCreateNewsArticleInProgress = createSelector(selectModuleState, state => state.isNewsArticleCreateInProgress);

export const selectNewsArticleComments = createSelector(selectModuleState, state => state.newsArticleComments);
export const selectNewsArticleCommentsLoading = createSelector(selectModuleState, state => state.newsArticleCommentsLoading);

export const selectSelectedNewsArticleRegionId = createSelector(selectModuleState, state => state.selectedNewsArticleRegionId);
export const selectSelectedNewsArticleRegion = createSelector(
  selectModuleState,
  state => state.selectedNewsArticle!.regions.find(item => item.id === state.selectedNewsArticleRegionId)!,
);
