import { ActionReducerMap } from '@ngrx/store';

import * as actionLogsReducer from './action-logs/action-logs.reducer';
import * as agreementsReducer from './agreements/agreements.reducer';
import * as announcementsReducer from './announcements/announcements.reducer';
import * as functionalRolesReducer from './authorization/functional-roles/functional-roles.reducer';
import * as permissionsReducer from './authorization/permissions/permissions.reducer';
import * as roleGroupsReducer from './authorization/role-groups/role-groups.reducer';
import * as authzRolesReducer from './authorization/roles/roles.reducer';
import * as commonDataReducer from './common-data/common-data.reducer';
import * as customersReducer from './customers/customers.reducer';
import * as documentsReducer from './documents/documents.reducer';
import * as fileManagementBranchesReducer from './file-management/branches/branches.reducer';
import * as fileManagementFilesReducer from './file-management/files/files.reducer';
import * as identityReducer from './identity/identity.reducer';
import * as commentsReducer from './marketing/comments/comments.reducer';
import * as topicsReducer from './marketing/topics/topics.reducer';
import * as newsArticleReducer from './news-articles/news-articles.reducer';
import * as newsRegionsReducer from './news-articles/news-regions/news-regions.reducer';
import * as notificationsExternalTemplatesReducer from './notifications/external-templates/external-templates.reducer';
import * as notificationsLogsReducer from './notifications/logs/logs.reducer';
import * as notificationsReceiverGroupsReducer from './notifications/receiver-groups/receiver-groups.reducer';
import * as notificationsTemplateGroupsReducer from './notifications/template-groups/template-groups.reducer';
import * as notificationsTemplatesReducer from './notifications/templates/templates.reducer';
import * as partnersReducer from './partners/partners.reducer';
import * as assessmentsReducer from './products-and-orders/assessments/assessments.reducer';
import * as productReducer from './products-and-orders/products/products.reducer';
import * as userReducer from './users/users.reducer';

export interface AppState {
  user: userReducer.State;
  documents: documentsReducer.State;
  identity: identityReducer.State;
  actionLogs: actionLogsReducer.State;
  commonData: commonDataReducer.State;
  partners: partnersReducer.State;
  permissions: permissionsReducer.State;
  roles: authzRolesReducer.State;
  roleGroups: roleGroupsReducer.State;
  functionalRoles: functionalRolesReducer.State;
  agreements: agreementsReducer.State;
  customers: customersReducer.State;
  announcements: announcementsReducer.State;
  topics: topicsReducer.State;
  fileManagementBranches: fileManagementBranchesReducer.State;
  fileManagementFiles: fileManagementFilesReducer.State;
  products: productReducer.State;
  notificationReceiverGroups: notificationsReceiverGroupsReducer.State;
  notificationExternalTemplates: notificationsExternalTemplatesReducer.State;
  notificationTemplateGroups: notificationsTemplateGroupsReducer.State;
  notificationTemplates: notificationsTemplatesReducer.State;
  notificationLogs: notificationsLogsReducer.State;
  newsArticles: newsArticleReducer.State;
  newsRegions: newsRegionsReducer.State;
  assessments: assessmentsReducer.State;
  comments: commentsReducer.State;
}

export const appReducers: ActionReducerMap<AppState> = {
  user: userReducer.userReducer,
  commonData: commonDataReducer.commonDataReducer,
  documents: documentsReducer.documentsReducer,
  identity: identityReducer.identityReducer,
  actionLogs: actionLogsReducer.actionLogsReducer,
  partners: partnersReducer.partnersReducer,
  permissions: permissionsReducer.permissionsReducer,
  roles: authzRolesReducer.rolesReducer,
  roleGroups: roleGroupsReducer.roleGroupsReducer,
  functionalRoles: functionalRolesReducer.functionalRolesReducer,
  agreements: agreementsReducer.agreementsReducer,
  customers: customersReducer.customersReducer,
  announcements: announcementsReducer.announcementsReducer,
  topics: topicsReducer.topicsReducer,
  fileManagementBranches: fileManagementBranchesReducer.branchesReducer,
  fileManagementFiles: fileManagementFilesReducer.filesReducer,
  products: productReducer.productsReducer,
  notificationReceiverGroups: notificationsReceiverGroupsReducer.groupsReducer,
  notificationExternalTemplates: notificationsExternalTemplatesReducer.externalTemplatesReducer,
  notificationTemplateGroups: notificationsTemplateGroupsReducer.templateGroupsReducer,
  notificationTemplates: notificationsTemplatesReducer.templatesReducer,
  notificationLogs: notificationsLogsReducer.notificationLogsReducer,
  newsArticles: newsArticleReducer.newsArticlesReducer,
  newsRegions: newsRegionsReducer.newsRegionsReducer,
  assessments: assessmentsReducer.assessmentsReducer,
  comments: commentsReducer.commentsReducer,
};

export const getRootState = (s: AppState): AppState => s;
